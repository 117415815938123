import React, { Fragment } from "react";
import { Table } from "reactstrap";

function DateTimeReport({ dataSource, header }) {
  return (
    <Fragment>
      <Table bordered>
        <thead>
          <tr>
            {header.map((item, i) => (
              <th key={i} >{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((data, index) => {
            return (
              <tr key={index}>
                {data.map((item, i) => {
                  return <td key={i}>{item}</td>
                })}
              </tr>)
          })}
        </tbody>
      </Table>
    </Fragment>
  )
}
export default DateTimeReport;
