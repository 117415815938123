import {
  GET_USER_COURSE,
  GET_USER_REGISTRATION,
  GET_USER_PROGRESS,
  MODULE_HEADER,
  GET_USER_SCORE,
  GET_REPORT_BY_DATE_TIME,
  REQUEST_DATA,
  REQUEST_SUCCESS,
} from "./types";

const INIT_STATE = {
  userCourseReport: [],
  userRegistration: [],
  userProgress: [],
  moduleHeader: [],
  userScore: [],
  dateTimeReport: [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_COURSE:
      return { ...state, userCourseReport: action.data }
    case GET_USER_REGISTRATION:
      return { ...state, userRegistration: action.data }
    case GET_USER_PROGRESS:
      return { ...state, userProgress: action.data }
    case MODULE_HEADER:
      return { ...state, moduleHeader: action.data }
    case GET_USER_SCORE:
      return { ...state, userScore: action.data }
    case GET_REPORT_BY_DATE_TIME:
      return { ...state, dateTimeReport: action.data }
    case REQUEST_DATA:
      return { ...state, loading: true }
    case REQUEST_SUCCESS:
      return { ...state, loading: false }
    default:
      return { ...state }
  }
}

