import styled from "styled-components";

export const ImagLogo = styled.img`
  width: 12rem;
`;

export const Avatar = styled.img`
  width: 2rem;
  margin-top: 0.2rem;
  cursor: pointer;
`;
export const NavItemCustom = styled.span`
    margin-left: 2rem;
    color: white !important;
    cursor: pointer;
`;
export const WrapLogo = styled.div`
    @media(max-width: 576px){
    float: left;
    margin-left: 1.5rem;
    }
`;