import { createSelector } from 'reselect'

const courses = (state) => state.course.courses;

export const coursesSelector = createSelector(courses, (courses) => {
    const course = courses.filter((course) => {
        course.label = course.title;
        course.value = course.id;
        return course;
    })
    return course;
});