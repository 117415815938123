import { GET_ALL_TIME_USER, GET_TOP_USER, GET_LAST_USAGE_LAST_MONTH } from "./type";
import Axios from "axios";
import { requestData, requestDataSuccess } from "../report/action";


export const setAllTimeUsers = (data) => ({
  type: GET_ALL_TIME_USER, data
});

export const setTopUsers = (user) => ({
  type: GET_TOP_USER, user
});

export const setUsageLastMonth = (data) => ({
  type: GET_LAST_USAGE_LAST_MONTH, data
});


export const getAllTimeUsersAsync = () => async (dispatch) => {
  dispatch(requestData())
  try {
    const response = await Axios({
      method: "GET",
      url: "https://us-central1-impacthublms.cloudfunctions.net/app/users/get-all-time-users",
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS,
      }
    })
    const app = response.data.data;
    dispatch(setAllTimeUsers(app));
    dispatch(requestDataSuccess());
  }
  catch (err) {
    console.log(err)
  }
};

export const getTopUserAsync = () => async (dispatch) => {
  dispatch(requestData())
  try {
    const response = await Axios({
      method: "GET",
      url: "https://us-central1-impacthublms.cloudfunctions.net/app/users/get-top-10-active-user-today",
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    })

    const topUsers = response.data.data;
    dispatch(setTopUsers(topUsers));
    dispatch(requestDataSuccess());
  } catch (err) {
    console.log(err)
  }
}

export const getUsageLastMonthAsync = () => async (dispatch) => {
  dispatch(requestData())
  try {
    const response = await Axios({
      method: "GET",
      url: "https://us-central1-impacthublms.cloudfunctions.net/app/users/get-usage-in-last-30-days",
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    })

    const usageInfo = response.data.data;
    dispatch(setUsageLastMonth(usageInfo));
    dispatch(requestDataSuccess());
  } catch (err) {
    console.log(err)
  }
}

