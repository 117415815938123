export const typeOfReport = [
    { label: "Users & Courses", value: "users_course" },
    { label: "Users Registration", value: "user_registration" },
    { label: "User Progress", value: "user_progress" },
    { label: "Score Report", value: "score_report" },
    { label: "Data By Timelines", value: "data_by_timelines" },
];


export const dataSource = [
    {
        firstName: "Len",
        lastName: "Novo",
        email: "lenovo@info.com",
        phoneNumber: '+01134567890',
        organizationName: 'Lenovo',
        typeOfOrganization: 'company',
        position: 'MBA',
        courseOneEnrolledDate: '20/02/20202',
        courseTwoEnrolledDate: '20/02/20202',
        certificatedUrl: 'kjdfuw9ni3kdjkdsfjkdfjdkfjkdf',
    },
    {
        firstName: "Len",
        lastName: "Novo",
        email: "lenovo@info.com",
        phoneNumber: '+01134567890',
        organizationName: 'Lenovo',
        typeOfOrganization: 'company',
        position: 'MBA',
        courseOneEnrolledDate: '20/02/20202',
        courseTwoEnrolledDate: '20/02/20202',
        certificatedUrl: 'kjdfuw9ni3kdjkdsfjkdfjdkfjkdf',
    }

];
export const dataSourceUserRegister = [
    {
        registered_Date: "Len",
        first_Name: "Novo",
        email: "lenovo@info.com",
        phoneNumber: '+01134567890',
        organizationName: 'Lenovo',
        typeOfOrganization: 'company',
        position: 'MBA',
        province: '20/02/20202',
        gender: '20/02/20202',
        yearOfBirth: "2020",
        enrolledCourse: "12345",
    },
    {
        registered_Date: "Len",
        first_Name: "Novo",
        email: "lenovo@info.com",
        phoneNumber: '+01134567890',
        organizationName: 'Lenovo',
        typeOfOrganization: 'company',
        position: 'MBA',
        province: '20/02/20202',
        gender: '20/02/20202',
        yearOfBirth: "2020",
        enrolledCourse: "12345",
    },

];