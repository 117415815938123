import React from "react";
import Layout from "../layout";
import {Container, Col, Button} from "reactstrap";
import {PAGE_NOT_FOUND} from "../../constants/defaultValues";
import {NavLink} from "react-router-dom";
import {Image404} from "./styled";
import {LOGIN} from "../../constants/routes";

function PageNotFound() {
  return (
    <Layout>
      <Container>
        <center>
          <Col md="6" fluid>
            <Image404 src={PAGE_NOT_FOUND} alt="page not found" />
            <NavLink to={LOGIN}>
              <Button color="info">Back to login page</Button>
            </NavLink>
          </Col>
        </center>
      </Container>
    </Layout>
  );
}

export default PageNotFound;
