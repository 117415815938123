import React, { Fragment } from "react";
import { Table } from "reactstrap";

function UserScoreReport({ header, dataSource }) {
  return (
    <Fragment>
      <Table bordered>
        <thead>
          <tr>
            {header.map((head, index) => {
              const { title } = head;
              return <th key={index}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((data, index) => {
            const {
              firstName,
              lastName,
              email,
              phoneNumber,
              organization,
              organizationType,
              position,
              enrollDate,
              modulesPercentage,
            } = data;
            const objects = Object.values(modulesPercentage);
            return (
              <tr key={index}>
                <td>{firstName}</td>
                <td>{lastName}</td>
                <td>{email}</td>
                <td>{phoneNumber}</td>
                <td>{organization}</td>
                <td>{organizationType}</td>
                <td>{position}</td>
                <td>{enrollDate}</td>
                {objects.length && objects.map((item, index) => {
                  return (<td key={index}>
                    {item.length && item.map((subItem, index) => {
                      return <small key={index}>{`${index + 1} #Score: ${subItem},`} <br /></small>
                    })}
                  </td>)
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  )
}
export default UserScoreReport;
