import React, {Fragment} from "react";
import Header from "./header";
import Loading from "../loading";

function Layout({children}) {
  return (
    <Fragment>
      <Header />
      {children}
      <Loading />
    </Fragment>
  );
}

export default Layout;
