import Axios from "axios";
import { GET_USER_COURSE, GET_USER_REGISTRATION, GET_USER_PROGRESS, MODULE_HEADER, GET_USER_SCORE, GET_REPORT_BY_DATE_TIME, REQUEST_DATA, REQUEST_SUCCESS } from "./types";
import { loginError } from "../auth/action";

export const getUsersCourse = (data) => ({
  type: GET_USER_COURSE, data
});

export const getUserRegistration = (data) => ({
  type: GET_USER_REGISTRATION, data
});

export const getUserProgress = (data) => ({
  type: GET_USER_PROGRESS, data
});

export const getModuleHeader = (data) => ({
  type: MODULE_HEADER, data
});

export const getUserScore = (data) => ({
  type: GET_USER_SCORE, data
});

export const getReportByDateTime = (data) => ({
  type: GET_REPORT_BY_DATE_TIME, data
});

export const requestData = () => ({
  type: REQUEST_DATA,
})
export const requestDataSuccess = () => ({
  type: REQUEST_SUCCESS,
})

export const getReportByDateTimeAsync = (startDate, endDate) => async (dispatch) => {
  dispatch(requestData());
  try {
    const response = await Axios({
      method: "GET",
      url: `https://us-central1-impacthublms.cloudfunctions.net/app/users/course-and-user-timelines?startDate=${startDate}&endDate=${endDate}`,
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    })
    dispatch(getReportByDateTime(response.data.data));
    dispatch(requestDataSuccess());
  }
  catch (err) {
    dispatch(loginError("You make a mistake (maybe you forgot select start date or end date) please refresh your browser it will refresh whole of page."))
    console.log(err);
  }
}

export const getUserScoreAsync = (courseId) => async (dispatch) => {
  dispatch(requestData());
  try {
    const response = await Axios({
      method: "GET",
      url: `https://us-central1-impacthublms.cloudfunctions.net/app/courses/${courseId}/user-score-report`,
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    })
    dispatch(getModuleHeader(response.data.modules));
    dispatch(getUserScore(response.data.data));
    dispatch(requestDataSuccess());
  }
  catch (err) {
    console.log(err);
  }
}

export const getUserProgressAsync = (courseId) => async (dispatch) => {
  dispatch(requestData());
  try {
    const response = await Axios({
      method: "GET",
      url: `https://us-central1-impacthublms.cloudfunctions.net/app/courses/${courseId}/user-progresses`,
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    });
    dispatch(getUserProgress(response.data.data));
    dispatch(getModuleHeader(response.data.modules));
    dispatch(requestDataSuccess());
  }
  catch (err) {
    console.log(err);
  }
}

export const getUserRegistrationAsync = () => async (dispatch) => {
  dispatch(requestData());
  try {
    const response = await Axios({
      method: "GET",
      url: "https://us-central1-impacthublms.cloudfunctions.net/app/users/get-user-registrations",
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    })
    dispatch(getUserRegistration(response.data.data))
    dispatch(requestDataSuccess());
  }
  catch (err) {
    console.log(err)
  }
}


export const getUserCourseAsync = (courseId) => async (dispatch) => {
  dispatch(requestData());
  try {
    const response = await Axios({
      method: "GET",
      url: `https://us-central1-impacthublms.cloudfunctions.net/app/courses/${courseId}/users`,
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    })
    dispatch(getUsersCourse(response.data.data))
    dispatch(requestDataSuccess());
  }
  catch (err) {
    console.log(err)
  }
}

