import { GET_ALL_TIME_USER, GET_TOP_USER, GET_LAST_USAGE_LAST_MONTH } from "./type";

const INIT_STATE = {
  app: [],
  topUsers: [],
  usageLastMont: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TIME_USER:
      return { ...state, app: action.data }
    case GET_TOP_USER:
      return { ...state, topUsers: action.user }
    case GET_LAST_USAGE_LAST_MONTH:
      return { ...state, usageLastMont: action.data }
    default:
      return { ...state }
  }
};