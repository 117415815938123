import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Dashboard from "../view/dashboard";
import Report from "../view/report";
import NotFound from "../components/not-found";
import {REPORT, DASHBOARD} from "../constants/routes";

function Private() {
  return (
    <Switch>
      <Route exact path={DASHBOARD} component={Dashboard} />
      <Route exact path={REPORT} component={Report} />
      <Redirect from="/" to={DASHBOARD} />
      <Route exact component={NotFound} />
    </Switch>
  );
}
export default Private;
