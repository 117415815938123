import { AUTH_LOGIN_USER, AUTH_LOGOUT_USER, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_ERROR } from "./types";
import { authRef, firestoreRef } from "../../lib/firebase";
import { setLocalStorage } from "../../lib/utils";
import { createBrowserHistory } from "history"
const history = createBrowserHistory();
export const loginUserWithEmailPassword = (user) => ({
  type: AUTH_LOGIN_USER,
  payload: { user }
});

export const logoutUser = () => ({
  type: AUTH_LOGOUT_USER,
});

export const loginSuccess = (userId) => ({
  type: AUTH_LOGIN_SUCCESS, userId
});

export const loginError = (message) => ({
  type: AUTH_LOGIN_ERROR, message
});

export const loginUserWithEmailPasswordAsync = (password, email) => async (dispatch) => {
  try {
    await authRef
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        firestoreRef
          .collection("userAdmin")
          .get()
          .then((querySnapshot) => {
            let isAdmin = false;
            querySnapshot.forEach((doc) => {
              if (doc.data().id === res.user.uid) return isAdmin = true;
            });
            if (isAdmin) {
              dispatch(loginSuccess(res.user.uid));
              setLocalStorage('Authorize', true);
              setLocalStorage('userId', res.user.uid);
              history.push('/dashboard');
              window.location.reload();
            } else {
              dispatch(loginError("Your account has not role to access."));
            }
          });
      })
  } catch (err) {
    console.log(err);
    dispatch(loginError(err.message))
  }

}

export const logoutUserAsync = () => async (dispatch) => {
  try {
    await authRef
      .signOut()
      .then(() => {
        dispatch(logoutUser());
        localStorage.removeItem('Authorize');
        localStorage.removeItem('userId');
        history.push('/login');
        window.location.reload();
      })
  }
  catch (err) {
    console.log(err);
  }
}
