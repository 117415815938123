import { createSelector } from 'reselect'

const moduleHeaders = (state) => state.report.moduleHeader;
const userProgress = (state) => state.report.userProgress;
const userScores = (state) => state.report.userScore;
const dateTimes = (state) => state.report.dateTimeReport;
const userRegistration = (state) => state.report.userRegistration;

export const moduleHeaderSelector = createSelector(moduleHeaders, (moduleHeaders) => {
  const moduleHeader = moduleHeaders.map((moduleHeader) => {
    return {
      field: !moduleHeader.rank ? 0 : moduleHeader.rank.toString(),
      title: moduleHeader.title
    };
  })
  return moduleHeader;
});

export const dataTimeReportSelector = createSelector(dateTimes, (dateTimes) => {
  return dateTimes.results || [];
});

export const dataTimeReportHeaderSelector = createSelector(dateTimes, (dateTimes) => {
  const headers = dateTimes.headers || [];
  return headers.map((item, i) => {
    return { field: i.toString(), title: item };
  });
});

export const userScoreSelector = createSelector(userScores, (userScores) => {
  const data = userScores.map((item) => {
    Object.entries(item.modulesPercentage).forEach(([key, value]) => {
      item[key] = value.join() || 0;
    });
    return item;
  })
  return data;
});

export const userProgressSelector = createSelector(userProgress, (userProgress) => {
  const moduleHeader = userProgress.map((item) => {
    Object.entries(item.modulesPercentage).forEach(([key, value]) => {
      item[key] = value;
    });
    return item;
  })
  return moduleHeader;
});

export const userRegistrationSelector = createSelector(userRegistration, (userRegistration) => {
  if (userRegistration) {
    let data = userRegistration.map((item) => {
      item.course = item.courses.map((item) => item.title).join();
      return { ...item }
    });

    return data;
  }
});
