import React from "react";

function BoxGraphFemale({title, percentage, color}) {
  return (
    <div className="bg-warning" style={{width: "100%"}}>
      <div className="p-2 text-white" style={{width: percentage + "%", background: color}}>
        <b>{percentage}%</b>
        <br />
        <small>{title}</small>
      </div>
    </div>
  );
}

export default BoxGraphFemale;
