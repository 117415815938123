import React, {Fragment} from "react";
import {Table} from "reactstrap";

function UserRegisterReport({header, dataSource}) {
  return (
    <Fragment>
      <Table bordered>
        <thead>
          <tr>
            {header.map((head, index) => {
              const {title} = head;
              return <th key={index}>{title}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((data, index) => {
            const {
              registerDate,
              firstName,
              lastName,
              email,
              phoneNumber,
              organization,
              organizationType,
              position,
              birthYear,
              province,
              gender,
              course,
            } = data;

            return (
              <tr key={index}>
                <td>{registerDate}</td>
                <td>{firstName}</td>
                <td>{lastName}</td>
                <td>{email}</td>
                <td>{phoneNumber}</td>
                <td>{organization}</td>
                <td>{organizationType}</td>
                <td>{position}</td>
                <td>{province}</td>
                <td>{gender}</td>
                <td>{birthYear}</td>
                <td>
                  {course.split(",").map((item, i) => (
                    <p key={i}>{item}</p>
                  ))}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}
export default UserRegisterReport;
