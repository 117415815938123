import React, {Fragment} from "react";
import {Table} from "reactstrap";

function UserProgressReport({header, dataSource}) {
  return (
    <Fragment>
      <Table bordered>
        <thead>
          <tr>
            {dataSource &&
              header.map((head, index) => {
                const {title} = head;
                return <th key={index}>{title}</th>;
              })}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((data, index) => {
            const {
              firstName,
              lastName,
              gender,
              province,
              email,
              phoneNumber,
              organization,
              organizationType,
              position,
              enrollDate,
              completedDate,
              modulesPercentage,
            } = data;
            const objects = Object.values(modulesPercentage);
            return (
              <tr key={index}>
                <td>{firstName}</td>
                <td>{lastName}</td>
                <td>{gender}</td>
                <td>{province}</td>
                <td>{email}</td>
                <td>{phoneNumber}</td>
                <td>{organization}</td>
                <td>{organizationType}</td>
                <td>{position}</td>
                <td>{enrollDate}</td>
                <td>{completedDate}</td>
                {objects.length &&
                  objects.map((data, index) => {
                    return <td key={index}>{data}</td>;
                  })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
}
export default UserProgressReport;
