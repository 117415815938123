import React from "react";
import {useSelector} from "react-redux";
import {Modal, ModalBody, Spinner} from "reactstrap";

function Loading() {
  const loadingData = useSelector((state) => state.report.loading);
  return (
    <Modal isOpen={loadingData}>
      <ModalBody className="text-center" style={{background: "none", marginTop: "40%"}}>
        <Spinner type="grow" color="info" style={{width: "2rem", height: "2rem"}} />
      </ModalBody>
    </Modal>
  );
}
export default Loading;
