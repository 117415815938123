import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import NotFound from "../components/not-found";
import {LOGIN} from "../constants/routes";
import LogIn from "../view/user-management/user-login";

function Public() {
  return (
    <Switch>
      <Route exact path={LOGIN} component={LogIn} />
      <Redirect exact from="/" to={LOGIN} />
      <Route exact component={NotFound} />
    </Switch>
  );
}
export default Public;
