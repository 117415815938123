import React, {Fragment} from "react";
import {Table} from "reactstrap";
import {EMPTY_DATA} from "../../constants/defaultValues";
import {ImageEmpty} from "./styled";
function TableEmpty({title}) {
  return (
    <Fragment>
      <Table bordered>
        <thead>
          <tr>
            <th>
              <center>
                <ImageEmpty src={EMPTY_DATA} alt="Nodata" style={{}} />
                <h3>{title}</h3>
              </center>
            </th>
          </tr>
        </thead>
      </Table>
    </Fragment>
  );
}

export default TableEmpty;
