import { GET_COURSES } from "./types";
const INIT_STATE = {
  courses: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COURSES: {
      return { ...state, courses: action.courses }
    }
    default:
      return { ...state };
  }
}