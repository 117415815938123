import React from "react";
import {Col, Row} from "reactstrap";
import {WrapBoxInfo} from "./styled";

function CardListCourse({name, enrolled, graduated, female, noneCity}) {
  return (
    <Col md="12" sm="12" xs="12" className="mb-4">
      <WrapBoxInfo className="bg-white">
        <Row>
          <Col md="3">
            <WrapBoxInfo className="bg-warning p-5">
              <small>COURSE:</small>
              <br />
              <b>{name}</b>
            </WrapBoxInfo>
          </Col>
          <Col md="9">
            <Row>
              <Col md="6" sm="8" xs="8">
                <div className="p-4">
                  <br />
                  <span># of enrolled:</span>
                  <br />
                  <span>% of graduated:</span>
                  <br />
                  <span>% of female:</span>
                  <br />
                  <span>% of non-Phnom Penh</span>
                </div>
              </Col>
              <Col md="6" sm="4" xs="4">
                <div className="p-4">
                  <br />
                  <b>{enrolled}</b>
                  <br />
                  <b>{graduated}%</b>
                  <br />
                  <b>{female}%</b>
                  <br />
                  <b>{noneCity}%</b>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </WrapBoxInfo>
    </Col>
  );
}
export default CardListCourse;
