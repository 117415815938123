export const userCourseHeader = [
    { field: "firstName", title: "First Name" },
    { field: "lastName", title: "Last Name" },
    { field: "email", title: "Email" },
    { field: "phoneNumber", title: "Phone Number" },
    { field: "organization", title: "Organization Name" },
    { field: "organizationType", title: "Type of Organization" },
    { field: "position", title: "Position" },
    { field: "province", title: "Province" },
    { field: "enrollDate", title: "Enrolled Date" },
    { field: "completedDate", title: "Completed Date" },
    { field: "certificatedUrl", title: "Certificated URL" },
];

export const userRegisterHeader = [
    { field: "registerDate", title: "Registered Date" },
    { field: "firstName", title: "First Name" },
    { field: "lastName", title: "Last Name" },
    { field: "email", title: "Email" },
    { field: "phoneNumber", title: "Phone Number" },
    { field: "organization", title: "Organization Name" },
    { field: "organizationType", title: "Type of Organization" },
    { field: "position", title: "Position" },
    { field: "province", title: "Province" },
    { field: "gender", title: "Gender" },
    { field: "birthYear", title: "Year of Birth" },
    { field: "course", title: "Enrolled Course" },
];

export const userProgressHeader = [
    { field: "firstName", title: "First Name" },
    { field: "lastName", title: "Last Name" },
    { field: "gender", title: "Gender" },
    { field: "province", title: "Province" },
    { field: "email", title: "Email" },
    { field: "phoneNumber", title: "Phone Number" },
    { field: "organization", title: "Organization Name" },
    { field: "organizationType", title: "Type of Organization" },
    { field: "position", title: "Position" },
    { field: "enrollDate", title: "Enrolled Date" },
    { field: "completedDate", title: "Completed Date" },
];

export const userScoreHeader = [
    { field: "firstName", title: "First Name" },
    { field: "lastName", title: "Last Name" },
    { field: "email", title: "Email" },
    { field: "phoneNumber", title: "Phone Number" },
    { field: "organization", title: "Organization Name" },
    { field: "organizationType", title: "Type of Organization" },
    { field: "position", title: "Position" },
    { field: "enrollDate", title: "Enrolled Date" },
];
