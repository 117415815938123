import React, {useState, useEffect} from "react";
import Layout from "../../components/layout";
import {Container, Row, Col} from "reactstrap";
import {WrapContainerBackground, WrapBoxInfo, ButtonViewAll} from "./styled";
import CardListCourse from "../../components/card-list-course";
import {useSelector, useDispatch} from "react-redux";
import {
  getAllTimeUsersAsync,
  getTopUserAsync,
  getUsageLastMonthAsync,
} from "../../stores/app/action";
import BoxGraph from "../../components/box-graph";
import WrapLabelInfo from "../../components/wrap-box-info";
import {getCoursesAsync} from "../../stores/course/action";
import BoxGraphFemale from "../../components/box-graph-female";

function Dashboard() {
  const dispatch = useDispatch();

  const [view, setView] = useState(false);
  const handleViewAll = () => {
    setView(!view);
  };
  useEffect(() => {
    dispatch(getAllTimeUsersAsync());
    dispatch(getTopUserAsync());
    dispatch(getUsageLastMonthAsync());
    dispatch(getCoursesAsync());
  }, [dispatch]);

  const courses = useSelector((state) => state.course.courses);
  const topUsers = useSelector((state) => state.app.topUsers);
  const {
    totalActiveUser,
    totalUserCompleteCourse,
    totalUserEnrollCourse,
    totalUserEnrollMoreThanOneCoursePercentage: moreThenTwoCourse,
    totalUserLogin,
    totalUserUsageInMinuteAvg,
  } = useSelector((state) => state.app.usageLastMont);

  const {
    totalUserEnrollMoreThanOneCoursePercentage,
    totalUser,
    totalUserFemalePercentage,
    totalUserInPPPercentage,
    totalUserInSRPercentage,
    totalUserInBTBPercentage,
    totalUserNoInPPorSRorBTBPercentage,
  } = useSelector((state) => state.app.app);

  return (
    <Layout>
      {/* Blog 1 */}
      <Container fluid className="p-0">
        <WrapContainerBackground>
          <Container>
            <WrapContainerBackground>
              <Row>
                <Col md="6" sm="6" xs="12">
                  <WrapBoxInfo className="bg-white p-5">
                    <Row>
                      <Col md="12">
                        <h6>All-time User</h6>
                      </Col>
                      <Col md="12">
                        <Row>
                          <Col md="6" sm="6" xs="6" className="mb-4 text-center">
                            <h1>{totalUser}</h1>
                            <small>total Users</small>
                          </Col>
                          <Col md="6" sm="6" xs="6" className="mb-4 text-center">
                            <h1>
                              {totalUserEnrollMoreThanOneCoursePercentage}
                              <span className="fs-25">%</span>
                            </h1>
                            <small>enrolled 2+ courses</small>
                          </Col>
                        </Row>
                        <Col md="12">
                          <Row>
                            <BoxGraphFemale
                              title="Female"
                              percentage={totalUserFemalePercentage}
                              color="#01B4BA"
                            />
                          </Row>
                        </Col>
                        <br />
                        <Col md="12">
                          <Row>
                            <BoxGraph
                              title="Phnom Penh"
                              percentage={totalUserInPPPercentage}
                              color="#01B4BA"
                            />
                            <BoxGraph
                              title="Battambang"
                              percentage={totalUserInBTBPercentage}
                              color="#05C0C6"
                            />
                            <BoxGraph
                              title="Siem Reap"
                              percentage={totalUserInSRPercentage}
                              color="#0ED9E0"
                            />
                            <BoxGraph
                              title="Others"
                              percentage={totalUserNoInPPorSRorBTBPercentage}
                              color="#51EAEF"
                            />
                          </Row>
                        </Col>
                      </Col>
                    </Row>
                  </WrapBoxInfo>
                </Col>
                <Col md="6" sm="6" xs="12">
                  <WrapBoxInfo className="bg-white p-5">
                    <Row>
                      <Col md="12">
                        <h6>In the Last 30 days</h6>
                      </Col>
                      <Col md="12">
                        <Row>
                          <Col md="6" className="text-center mb-1">
                            <WrapLabelInfo title="Active Users" number={totalActiveUser} type="" />
                          </Col>
                          <Col md="6" className="text-center mb-1">
                            <WrapLabelInfo title="User Log-ins" number={totalUserLogin} />
                          </Col>

                          <Col md="6" className="text-center mb-1">
                            <WrapLabelInfo
                              title="Avg. Spent-time"
                              number={totalUserUsageInMinuteAvg}
                              type="mins"
                            />
                          </Col>
                          <Col md="6" className="text-center mb-1">
                            <WrapLabelInfo
                              title="Enrolled 2+ Courses"
                              number={moreThenTwoCourse}
                              type="%"
                            />
                          </Col>

                          <Col md="6" className="text-center mb-1">
                            <WrapLabelInfo
                              title="Course Enrollments"
                              number={totalUserEnrollCourse}
                              type=""
                            />
                          </Col>
                          <Col md="6" className="text-center mb-1">
                            <WrapLabelInfo
                              title="Completed Course"
                              number={totalUserCompleteCourse}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </WrapBoxInfo>
                </Col>
              </Row>
            </WrapContainerBackground>
          </Container>
        </WrapContainerBackground>
      </Container>
      {/* Blog 2 */}
      <Container fluid className="p-0">
        <Container>
          <Row>
            <Col md="12" sm="12" xs="12" className="mb-4">
              <WrapBoxInfo className="bg-white p-5 ">
                <b>Top 10 most active user of the day</b>
                <Col md="12" className="mt-2">
                  <Row>
                    {topUsers.map((user, index) => {
                      const {firstName, lastName, organization} = user;
                      return (
                        <Col md="6" key={index}>
                          <b>{`${index + 1}  ${firstName} ${lastName}`}</b>
                          <br />
                          <small className="ml-3">{organization}</small>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </WrapBoxInfo>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Blog 3 */}
      <Container fluid className="p-0">
        <Container>
          <Row>
            <Col md="12" className="mb-4">
              <Row>
                <Col md="6" sm="6" xs="6">
                  <b>Course Snapshots</b>
                </Col>
                <Col md="6" sm="6" xs="6" className="text-right">
                  <ButtonViewAll onClick={handleViewAll}>
                    {view ? "VIEW LESS" : "VIEW ALL"}
                  </ButtonViewAll>
                </Col>
              </Row>
            </Col>
            {view
              ? courses.map((data, index) => {
                  const {
                    title,
                    totalGraduatePercentage,
                    totalFemalePercentage,
                    totalEnroll,
                    totalNonInPPPercentage,
                  } = data;
                  return (
                    <CardListCourse
                      name={title}
                      enrolled={totalEnroll}
                      graduated={totalGraduatePercentage}
                      female={totalFemalePercentage}
                      noneCity={totalNonInPPPercentage}
                      key={index}
                    />
                  );
                })
              : courses.slice(0, 3).map((data, index) => {
                  const {
                    title,
                    totalGraduatePercentage,
                    totalFemalePercentage,
                    totalEnroll,
                    totalNonInPPPercentage,
                  } = data;
                  return (
                    <CardListCourse
                      name={title}
                      enrolled={totalEnroll}
                      graduated={totalGraduatePercentage}
                      female={totalFemalePercentage}
                      noneCity={totalNonInPPPercentage}
                      key={index}
                    />
                  );
                })}
          </Row>
        </Container>
      </Container>
    </Layout>
  );
}

export default Dashboard;
