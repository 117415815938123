import React from "react";

function BoxGraph({title, number, type}) {
  return (
    <div className="mb-3 p-0">
      <h1 className="mb-0">
        {number} {type && <span className="fs-25">{type}</span>}
      </h1>
      <small className="mt-0">{title}</small>
    </div>
  );
}

export default BoxGraph;
