import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "./auth/reducer";
import courseReducer from "./course/reducer";
import appReducer from "./app/reducer";
import userCourse from "./report/reducers";
const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
};

const rootReducer = combineReducers({
    auth: authReducer,
    course: courseReducer,
    app: appReducer,
    report: userCourse,
});

export default persistReducer(persistConfig, rootReducer);