import React from "react";
import {Col} from "reactstrap";

function BoxGraph({title, percentage, color}) {
  return (
    <Col md="3" sm="3" xs="3" className="p-2 text-white" style={{background: color}}>
      <b>{percentage}%</b>
      <br />
      <small>{title}</small>
    </Col>
  );
}

export default BoxGraph;
