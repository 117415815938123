import styled from "styled-components";

export const WrapContainerBackground = styled.div`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: linear-gradient(to bottom, #f75353 50%, #ffffff 50%);
    @media(max-width: 576px){
        background: #f75353;
    }
`;
export const WrapBoxInfo = styled.div`
    width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    /* height: 430px; */
    @media(max-width: 576px){
        margin-bottom: 20px;
    }
`;
export const WrapIconReport = styled.img`
    padding: 15px;    
    height: 15.5rem;
    @media(max-width: 767px){
        display: none;
    }
`;