import React, {useState, useCallback, Fragment} from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavbarText,
  Container,
  Col,
  Row,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {ImagLogo, Avatar, NavItemCustom, WrapLogo} from "./styled";
import {LOGO, AVATAR} from "../../../constants/defaultValues";
import {DASHBOARD, REPORT} from "../../../constants/routes";
import {NavLink} from "react-router-dom";
import {logoutUserAsync} from "../../../stores/auth/action";
import {useDispatch} from "react-redux";
import {getLocalStorage} from "../../../lib/utils";

function Header() {
  const getLocalAuthorize = getLocalStorage("Authorize");
  const getLocalUserId = getLocalStorage("userId");
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const toggle2 = () => setOpen(!dropdownOpen);

  const handleLogout = useCallback(() => {
    dispatch(logoutUserAsync());
  }, [dispatch]);

  return (
    <Container fluid className="bg-red p-0">
      <Container className="p-0">
        <Navbar color="red" light expand="md" className="bg-red">
          <NavbarBrand href="/">
            <ImagLogo src={LOGO} alt="Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Row className="w-100">
              <Col md="9" className="w-100 text-center mt-3 nav-item">
                {getLocalAuthorize && getLocalUserId && (
                  <Fragment>
                    <NavLink to={DASHBOARD}>
                      <NavItemCustom>Dashboard</NavItemCustom>
                    </NavLink>
                    <NavLink to={REPORT}>
                      <NavItemCustom>Report</NavItemCustom>
                    </NavLink>
                  </Fragment>
                )}
              </Col>

              <Col md="3" className="p-0 text-right">
                <WrapLogo>
                  <NavbarText>
                    <ButtonDropdown
                      direction="down"
                      isOpen={dropdownOpen}
                      toggle={toggle2}
                      className="drop-down-custom"
                    >
                      <DropdownToggle>
                        <Avatar src={AVATAR} alt="avatar" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header className="btn" onClick={handleLogout}>
                          <span onClick={handleLogout}>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </NavbarText>
                </WrapLogo>
              </Col>
            </Row>
          </Collapse>
        </Navbar>
      </Container>
    </Container>
  );
}

export default Header;
