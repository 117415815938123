import React from 'react';
import ReactDOM from 'react-dom';
// style
import "./styles/index.scss";
//Route
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import App from './view';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from "./stores/root-reducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'));
