
export const visibleTab = (function () {
  var stateKey,
    eventKey,
    keys = {
      hidden: 'visibilitychange',
      webkitHidden: 'webkitvisibilitychange',
      mozHidden: 'mozvisibilitychange',
      msHidden: 'msvisibilitychange',
    };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  return function (c) {
    if (c) document.addEventListener(eventKey, c);
    return !document[stateKey];
  };
})();

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getStoragePath = () => {
  // if (!imagePath) return IMAGE_DEFAULT;
  // return STORAGE_PREFIX_PATH.replace('@@@', imagePath.replace('/', '%2F'));
  return "";
};


export const getLocalStorage = (key, value = '') => {
  return !localStorage.getItem(key) ? value : localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
  value = value ? value : '';
  localStorage.setItem(key, value);
};
