// lib
import React, {useCallback, useState, useEffect} from "react";
import {Container, Row, Col, Button} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

// components
import Layout from "../../components/layout";
import DownloadExcel from "../../components/download-excel";

// utils
import {WrapBoxInfo, WrapIconReport} from "./styled";
import {SEO_REPORT} from "../../constants/defaultValues";
import {typeOfReport} from "./data";
import {userCourseHeader, userRegisterHeader, userProgressHeader, userScoreHeader} from "./header";
import UserCourseReport from "./users_course";
import UserRegisterReport from "./user_registration";
import UserProgressReport from "./user_progress";
import UserScoreReport from "./score_report";
import DateTimeReport from "./data_by_timelines";
import TableEmpty from "../../components/table-empty";
import {getCoursesAsync} from "../../stores/course/action";
import {coursesSelector} from "../../stores/course/selectors";
import {
  getUserCourseAsync,
  getUserRegistrationAsync,
  getUserProgressAsync,
  getUserScoreAsync,
  getReportByDateTimeAsync,
} from "../../stores/report/action";
import {
  moduleHeaderSelector,
  userProgressSelector,
  userScoreSelector,
  dataTimeReportSelector,
  dataTimeReportHeaderSelector,
  userRegistrationSelector,
} from "../../stores/report/selectors";

function Report() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [course, setCourse] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [childRef, setChildRef] = useState(null);
  const [headerReportProgress, setHeaderReportProgress] = useState(userProgressHeader);
  const [headerReportScore, setHeaderReportScore] = useState(userScoreHeader);
  const [defaultReportHeader, setDefaultReportHeader] = useState([]);
  const [defaultDataReport, setDefaultDataReport] = useState([]);
  const [reportName, setReportName] = useState("");
  const [validationSubmit, setValidationSubmit] = useState(true);
  const userCourseReport = useSelector((state) => state.report.userCourseReport);
  const userRegistrationReport = useSelector((state) => state.report.userRegistration);
  const error = useSelector((state) => state.auth.error);
  const userProgressReport = useSelector(userProgressSelector);
  const courses = useSelector(coursesSelector);
  const moduleHeaderSelectors = useSelector(moduleHeaderSelector);
  const userScoreSelectors = useSelector(userScoreSelector);
  const dateTimeReport = useSelector(dataTimeReportSelector);
  const dateTimeReportHeader = useSelector(dataTimeReportHeaderSelector);
  const testDataRegistrationSelector = useSelector(userRegistrationSelector);

  // Get all courses
  useEffect(() => {
    // console.log("userRegistrationReport", userRegistrationReport);
    dispatch(getCoursesAsync());
  }, [dispatch, userRegistrationReport]);

  useEffect(() => {
    if (!loading && moduleHeaderSelectors.length) {
      // setHeaderReportProgress([...headerReportProgress, ...moduleHeaderSelectors]);
      // setHeaderReportScore([...headerReportScore, ...moduleHeaderSelectors]);
      setLoading(true);
    }
  }, [headerReportProgress, moduleHeaderSelectors, loading, headerReportScore]);

  //validation submit
  useEffect(() => {
    (() => {
      if (selectedOption.value && course.id) {
        setValidationSubmit(false);
      } else if (selectedOption.value === "user_registration") {
        setValidationSubmit(false);
      } else if (selectedOption.value === "data_by_timelines") {
        if (startDate && endDate) setValidationSubmit(false);
      }
    })();
  }, [selectedOption, course, endDate, startDate]);

  // Select View Report
  const handleChangeReport = (selectedOption) => {
    setSelectedOption(selectedOption);
    setDefaultDataReport([]);
    setDefaultReportHeader([]);
    setCourse("");
    setValidationSubmit(true);
    if (selectedOption.value !== "data_by_timelines") {
      setEndDate("");
      setStartDate("");
    }
  };

  const handleChangeCourse = (course) => {
    setDefaultReportHeader([]);
    setDefaultDataReport([]);
    setCourse(course);
  };
  // handle submit report
  const handleSubmitReport = useCallback(() => {
    const courseId = course.id;
    let dateStart = moment(startDate).format("YYYY-MM-DD");
    let dateEnd = moment(endDate).format("YYYY-MM-DD");

    switch (selectedOption.value) {
      case "users_course": {
        dispatch(getUserCourseAsync(courseId));
        break;
      }
      case "user_registration": {
        dispatch(getUserRegistrationAsync());
        break;
      }
      case "user_progress": {
        dispatch(getUserProgressAsync(courseId));
        break;
      }
      case "score_report": {
        dispatch(getUserScoreAsync(courseId));
        break;
      }
      case "data_by_timelines": {
        dispatch(getReportByDateTimeAsync(dateStart, dateEnd));
        break;
      }
      default:
    }
  }, [dispatch, course, selectedOption, startDate, endDate]);
  // Switch Data Report
  useEffect(() => {
    switch (selectedOption.value) {
      case "users_course": {
        setDefaultDataReport(userCourseReport);
        setDefaultReportHeader(userCourseHeader);
        setReportName("users_course");
        break;
      }
      case "user_registration": {
        setDefaultDataReport(testDataRegistrationSelector);
        setDefaultReportHeader(userRegisterHeader);
        setReportName("user_registration");

        break;
      }
      case "user_progress": {
        setDefaultDataReport(userProgressReport);
        setDefaultReportHeader(headerReportProgress);
        setReportName("user_progress");

        break;
      }
      case "score_report": {
        setDefaultDataReport(userScoreSelectors);
        setDefaultReportHeader(headerReportScore);
        setReportName("score_report");
        break;
      }
      case "data_by_timelines": {
        setDefaultDataReport(dateTimeReport);
        setDefaultReportHeader(dateTimeReportHeader);
        setReportName("data_by_timelines");
        break;
      }
      default:
    }
  }, [
    selectedOption,
    headerReportProgress,
    headerReportScore,
    userCourseReport,
    userRegistrationReport,
    userProgressReport,
    userScoreSelectors,
    dateTimeReport,
    dateTimeReportHeader,
    testDataRegistrationSelector,
  ]);
  //download report
  const exportData = useCallback(() => {
    childRef.save();
  }, [childRef]);
  // Switch View Report
  let viewReport = "";
  switch (selectedOption.value) {
    case "users_course": {
      viewReport = (
        <UserCourseReport
          header={defaultReportHeader}
          dataSource={defaultDataReport}
          course={course}
        />
      );
      break;
    }
    case "user_registration": {
      viewReport = (
        <UserRegisterReport header={defaultReportHeader} dataSource={defaultDataReport} />
      );
      break;
    }
    case "user_progress": {
      viewReport = (
        <UserProgressReport
          header={[...defaultReportHeader, ...moduleHeaderSelectors]}
          dataSource={defaultDataReport}
        />
      );
      break;
    }
    case "score_report": {
      viewReport = (
        <UserScoreReport
          header={[...defaultReportHeader, ...moduleHeaderSelectors]}
          dataSource={defaultDataReport}
        />
      );
      break;
    }
    case "data_by_timelines": {
      viewReport = <DateTimeReport header={defaultReportHeader} dataSource={defaultDataReport} />;
      break;
    }
    default:
      viewReport = (
        <TableEmpty
          className="text-center text-danger"
          title="Please submit filter for show report"
        />
      );
  }

  return (
    <Layout>
      <Container fluid>
        <Container>
          <Col md="12" sm="12" xs="12" className="mb-5 mt-5 ">
            <Row>
              <Col md="3" className="p-0">
                <WrapBoxInfo className="bg-warning">
                  <center>
                    {error && alert(error)}
                    <WrapIconReport src={SEO_REPORT} alt="IconReport" />
                  </center>
                </WrapBoxInfo>
              </Col>
              <Col md="9" className="p-0">
                <WrapBoxInfo className="bg-report p-3">
                  <div className="p-2">
                    <Row>
                      <Col md="6">Select Report Template:</Col>
                      <Col md="6">
                        <Select
                          placeholder="Select Report Template"
                          value={selectedOption}
                          onChange={handleChangeReport}
                          options={typeOfReport}
                          name="selectedTemplate"
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="p-2">
                    <Row>
                      <Col md="6">Select Course:</Col>
                      <Col md="6">
                        <Select
                          isDisabled={
                            selectedOption.length < 1 ||
                            selectedOption.value === "data_by_timelines" ||
                            selectedOption.length < 1 ||
                            selectedOption.value === "user_registration"
                              ? true
                              : false
                          }
                          placeholder="Select Course"
                          value={course}
                          onChange={handleChangeCourse}
                          options={courses}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="p-2">
                    <Row>
                      <Col md="6">Select Date:</Col>
                      <Col md="6">
                        <Row>
                          <Col md="6" sm="6" xs="6">
                            <DatePicker
                              disabled={selectedOption.value !== "data_by_timelines" && true}
                              placeholderText="  Start Date"
                              selected={startDate}
                              maxDate={endDate}
                              onChange={(date) => setStartDate(date)}
                              className={`css-yk16xz-control w-100 ${
                                selectedOption.value !== "data_by_timelines" && "bg-disable"
                              }`}
                            />
                          </Col>
                          <Col md="6" sm="6" xs="6">
                            <DatePicker
                              disabled={selectedOption.value !== "data_by_timelines" && true}
                              placeholderText="  End Date"
                              selected={endDate}
                              minDate={startDate}
                              onChange={(date) => setEndDate(date)}
                              className={`css-yk16xz-control w-100 ${
                                selectedOption.value !== "data_by_timelines" && "bg-disable"
                              }`}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="p-2">
                    <Row>
                      <Col md="6"></Col>
                      <Col md="6" className="text-center">
                        <Button
                          onClick={handleSubmitReport}
                          color="info"
                          className="pl-5 pr-5 rounded-pill"
                          disabled={validationSubmit}
                        >
                          SUBMIT
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </WrapBoxInfo>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md="12" className="p-0">
                {defaultDataReport.length > 1 && (
                  <Button color="dark" className="rounded-pill" onClick={() => exportData()}>
                    DOWNLOAD REPORT
                  </Button>
                )}
              </Col>
              <Col md="12" className="p-0 mt-5 ">
                <div className="table-responsive">
                  <DownloadExcel
                    dataSources={defaultDataReport}
                    header={defaultReportHeader}
                    fileName={reportName}
                    setChildRef={setChildRef}
                  />
                  {viewReport}
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </Container>
    </Layout>
  );
}

export default Report;
