import React, {Fragment, useRef, useEffect} from "react";
import {ExcelExport, ExcelExportColumn} from "@progress/kendo-react-excel-export";
import {process} from "@progress/kendo-data-query";

function DownloadExcel({dataSources, fileName, header, setChildRef}) {
  const data = process(dataSources, {}).data;
  let _exporter = useRef();

  useEffect(() => {
    setChildRef(_exporter);
  }, [setChildRef, _exporter]);
  return (
    <Fragment>
      <ExcelExport
        data={data}
        fileName={`${fileName}.xlsx`}
        ref={(exporter) => {
          _exporter = exporter;
        }}
      >
        {header.map((item) => {
          return <ExcelExportColumn key={item.field} {...item} />;
        })}
      </ExcelExport>
    </Fragment>
  );
}

export default DownloadExcel;
