import React from "react";
import {Switch, Route} from "react-router-dom";
import ErrorBoundary from "../components/error-boundary";
import PrivateRoute from "../components/private-route";
import NotFound from "../components/not-found";
import Private from "./private";
import Public from "./public";

function RouteComponent() {
  return (
    <ErrorBoundary>
      <Switch>
        <PrivateRoute component={Private} authenticationComponent={Public} />
        <Route component={NotFound} />
      </Switch>
    </ErrorBoundary>
  );
}
export default RouteComponent;
