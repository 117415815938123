import React, { useEffect } from "react";
import { Router, useHistory } from "react-router-dom";
import RouteComponent from "../routes/index";
import { firebaseApp } from "../lib/firebase";
import { setLocalStorage } from "../lib/utils";
import { logoutUserAsync } from "../stores/auth/action";

function App() {
  const history = useHistory();

  useEffect(() => {
    firebaseApp.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userId = user.uid;
        setLocalStorage("Authorize", true);
        setLocalStorage("userId", userId);
      } else {
        logoutUserAsync();
      }
    });
  }, []);

  return (
    <Router history={history}>
      <RouteComponent />
    </Router>
  );
}
export default App;
