import Axios from "axios";
import { GET_COURSES } from "./types";

export const setCourses = (courses) => ({
  type: GET_COURSES, courses
})

export const getCoursesAsync = () => async (dispatch) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "https://us-central1-impacthublms.cloudfunctions.net/app/courses/get-course-snapshots",
      headers: {
        Authorization: process.env.REACT_APP_API_HEADERS
      }
    });
    dispatch(setCourses(response.data.data))
  } catch (err) {
    console.log(err);
  }
}