import { AUTH_LOGIN_USER, AUTH_LOGIN_ERROR, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_USER } from "./types";
import { getLocalStorage } from "../../lib/utils";

const INIT_STATE = {
  userId: getLocalStorage('userId'),
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_LOGIN_USER:
      return { ...state, }
    case AUTH_LOGIN_ERROR:
      return { ...state, error: action.message }
    case AUTH_LOGIN_SUCCESS:
      return { ...state, userId: action.userId }
    case AUTH_LOGOUT_USER:
      return { ...state, userId: null }
    default:
      return { ...state, }
  }
} 