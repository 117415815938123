import React, {useCallback, useState} from "react";
import Layout from "../../../components/layout";
import {WrapContainerLogIn} from "./styled";
import {Container, Col} from "reactstrap";
import {Button, FormGroup, Input} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {loginUserWithEmailPasswordAsync} from "../../../stores/auth/action";

function UserLogin() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const error = useSelector((state) => state.auth.error);
  const handleSubmit = useCallback(() => {
    dispatch(loginUserWithEmailPasswordAsync(password, userName));
  }, [dispatch, password, userName]);

  return (
    <Layout>
      <Container>
        <center>
          <Col md="6" fluid>
            <WrapContainerLogIn>
              {error && <span className="text-danger">{error}</span>}
              <h4>Log In</h4>
              <form>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="Email"
                    className="w-80"
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="Password"
                    name="password"
                    id="password"
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password"
                    className="w-80"
                  />
                </FormGroup>
                <FormGroup>
                  <Button onClick={handleSubmit} color="info">
                    Sing In
                  </Button>
                </FormGroup>
              </form>
            </WrapContainerLogIn>
          </Col>
        </center>
      </Container>
    </Layout>
  );
}

export default UserLogin;
