import React from "react";
import PropTypes from "prop-types";
import {Route} from "react-router-dom";
import {getLocalStorage} from "../../lib/utils";

const PrivateRoute = ({
  component: Component,
  authenticationComponent: AuthenticationComponent,
  ...rest
}) => {
  const getLocalAuthorize = getLocalStorage("Authorize");
  const getLocalUserId = getLocalStorage("userId");
  return (
    <Route
      render={(props) =>
        getLocalAuthorize && getLocalUserId ? (
          <Component isAuthorize={true} {...props} />
        ) : (
          <AuthenticationComponent isAuthorize={false} {...props} />
        )
      }
      {...rest}
    />
  );
};

PrivateRoute.propTypes = {
  rest: PropTypes.any,
  component: PropTypes.any,
  authenticationComponent: PropTypes.any,
};

export default PrivateRoute;
